import type { Theme } from "@/entities/theme";
import type { User } from "@/entities/user";

const BREAKPOINT_DESKTOP = 767;

export const countriesWithLegacyDesign: Readonly<User["country"][]> = ["mx"];

export const themeKeys: Readonly<Record<Theme, Theme>> = {
  dark: "dark",
  light: "light",
};

export const isDesktop = window.innerWidth > BREAKPOINT_DESKTOP;
