<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { groupPaths, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionTitle, SectionWrapper } from "@/entities/section";
import { useFormStore, useChangeFormAmount } from "@/features/form";
import { routeNames } from "@/shared/constants";
import { PaymentSystems } from "@/widgets/transaction";

const { t } = useI18n();
const router = useRouter();

const paymentMethodStore = usePaymentMethodStore();

const { initialPaymentMethods, paymentGroup, paymentMethod, filteredPaymentGroups, filteredPaymentMethods } =
  storeToRefs(paymentMethodStore);

const { changePaymentGroup, changePaymentMethod, changePaymentMethods } = paymentMethodStore;

const { changeAmount, changeAmountValidity, changeFields, setFields } = useFormStore();

const { setFormAmount } = useChangeFormAmount();

const resetData = () => {
  changeAmount("");
  changeAmountValidity(true);
  changePaymentGroup(null);
  changePaymentMethod(null);
  changePaymentMethods(initialPaymentMethods.value);
  setFields([]);
};

const selectPaymentGroup = async (paymentGroup: PaymentGroup) => {
  changePaymentGroup(paymentGroup);
  changePaymentMethods(Object.values(paymentGroup.paymentMethods));
  await router.push({
    name: groupPaths[paymentGroup.name] ?? routeNames.depositGroupPage,
  });
};

const selectPaymentMethod = async (paymentMethod: PaymentMethod) => {
  changePaymentMethod(paymentMethod);
  changeFields(paymentMethod.fields);
  setFormAmount();
  await router.push({ name: routeNames.depositBasePage });
};

onBeforeMount(resetData);
</script>

<template>
  <SectionWrapper class="scrollbar-hidden overflow-y-auto">
    <SectionTitle>{{ t("deposit.title") }}</SectionTitle>
    <PaymentSystems
      :payment-group="paymentGroup"
      :payment-groups="filteredPaymentGroups"
      :payment-method="paymentMethod"
      :payment-methods="filteredPaymentMethods"
      transaction="deposit"
      @select-payment-group="selectPaymentGroup"
      @select-payment-method="selectPaymentMethod"
    />
  </SectionWrapper>
</template>
