<script setup lang="ts">
import { storeToRefs } from "pinia";
import { toRef } from "vue";
import { useI18n } from "vue-i18n";

import type { PaymentMethod } from "@/entities/payment-method";
import { defaultLimits, getPaymentIcon, useBadge } from "@/entities/payment-method";
import { useThemeStore } from "@/entities/theme";
import type { Transaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { formatNumber } from "@/shared/lib";
import type { Size } from "@/shared/ui";
import { Badge, Card, Icon, Typography } from "@/shared/ui";

type Emits = {
  select: [paymentMethod: PaymentMethod];
};

interface Props {
  hasAmount?: boolean;
  isActive?: boolean;
  paymentMethod: PaymentMethod;
  size?: Extract<Size, "md" | "lg">;
  transaction: Transaction;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  hasAmount: true,
  isActive: false,
  size: "md",
});

const { t } = useI18n();

const { badge } = useBadge({
  isActive: toRef(() => props.isActive),
});

const { country, user } = storeToRefs(useUserStore());

const { theme } = storeToRefs(useThemeStore());

const onSelect = (paymentMethod: PaymentMethod) => emit("select", paymentMethod);

const onClick = () => {
  if (props.isActive) {
    return;
  }

  onSelect(props.paymentMethod);
};
</script>

<template>
  <Badge
    :class="badge.customClass.root"
    :color="badge.color"
    :custom-class="badge.customClass.content"
    is-custom
    :is-visible="badge.isVisible"
  >
    <template #default>
      <Card
        class="highlight-none h-full select-none overflow-hidden border-0.5 text-center"
        :is-active="isActive"
        is-scalable
        :size="size"
        @click="onClick"
      >
        <Icon
          class="mb-6 h-7.5"
          :name="
            getPaymentIcon({
              country,
              name: paymentMethod.name,
              theme,
              transaction,
              type: 'base',
            })
          "
        />
        <Typography class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ paymentMethod?.text[user.lang] ?? paymentMethod?.text.en }}
        </Typography>
        <Typography
          v-if="hasAmount"
          class="mt-1 break-all text-gray-350 dark:text-pearl"
          size="sm"
        >
          {{
            t("paymentMethod.upTo", {
              sum: formatNumber(
                { currency: user.currency },
                (paymentMethod.amount_rules?.[user.currency] ?? defaultLimits).max,
              ),
            })
          }}
        </Typography>
      </Card>
    </template>
    <template #content>
      <Icon
        :class="badge.customClass.icon"
        :name="badge.icon"
      />
    </template>
  </Badge>
</template>
