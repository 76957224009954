/**
 * - заменить у коры алгоритм пост мессаджа в виджет на только после получение Loaded
 * - заменить у коры поле locale на lang в передаваемых в пост-мессадже
 */

import "@/app/index.css";

import { storeToRefs } from "pinia";
import { createApp } from "vue";

import App from "@/app";
import { clickOutsideDirective, sanitizeHtmlDirective } from "@/app/directives";
import { useAppStore } from "@/app/model";
import { consolePlugin, datadogPlugin, i18n, i18nPlugin, socketPlugin } from "@/app/plugins";
import { router, routerV2, store } from "@/app/providers";
import { useBonusStore } from "@/entities/bonus";
import type { BonusData } from "@/entities/parent";
import { useParentStore } from "@/entities/parent";
import { useThemeStore } from "@/entities/theme";
import { useUserStore } from "@/entities/user";
import { pick } from "@/shared/lib";
import { bridgeService, appStorage } from "@/shared/services";

window.__appStartTime = performance.now();

void datadogPlugin.install();

const app = createApp(App);
app.use(store);

// INIT
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { changeUser } = userStore;

if (bridgeService.isWebView()) {
  const webviewUser = await bridgeService.loadFromWebView();
  changeUser(webviewUser);
} else if (bridgeService.isIframe()) {
  const { user: userData, parent: parentData } = await bridgeService.loadFromIframe();

  changeUser({
    ...user.value,
    ...userData,
  });

  const { changeActiveBonusId, changeBonuses, changeBonusDisplayStatus } = useBonusStore();

  const updateBonuses = (payload: BonusData["payload"]) => {
    const bonuses = payload.bonusList.filter(
      (bonus) => bonus.type === "deposit" || (bonus.type === "freespin" && bonus.freespinBonusData?.depositRequired),
    );
    changeActiveBonusId(payload.bonusId ?? bonuses[0]?.id);
    changeBonuses(bonuses);
    changeBonusDisplayStatus(bonuses.length > 0);
  };

  updateBonuses(parentData);
  bridgeService.loadBonusesFromIframe(updateBonuses);

  const { changeParentData } = useParentStore();
  changeParentData("closingButton", parentData.closingButton);
  changeParentData("experiments", parentData.experiments);
  changeParentData("host", parentData.host);
  changeParentData("token", parentData.token);
  changeParentData("xOrigin", parentData.xOrigin);
  changeParentData("userId", parentData.userId);
  changeParentData("partnerKey", parentData.partnerKey);
}

changeUser({
  ...user.value,
  ...(appStorage.getUser() ?? {}),
});
// /INIT

app.directive("click-outside", clickOutsideDirective);
app.directive("sanitize-html", sanitizeHtmlDirective);

const { initStyles } = useAppStore();

const { parentData } = storeToRefs(useParentStore());

const { isLegacyDesign } = storeToRefs(useThemeStore());

const { isNativePlatform } = storeToRefs(useUserStore());

await initStyles();

app.use(i18n);
app.use(i18nPlugin, { user: user.value });
app.use(socketPlugin, {
  ...pick(parentData.value, ["host", "token", "xOrigin"]),
  isNativePlatform: isNativePlatform.value,
  user: user.value,
});
app.use(consolePlugin);
app.use(isLegacyDesign.value ? router : routerV2);

app.mount("#app");
