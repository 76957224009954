import { currencies } from "@1win/frontend-currencies";

import type { User } from "@/entities/user";

type Transition = "fade" | "positionTop" | "scale" | "slideDown" | "slideLeft" | "slideTop" | "slideRight";

export const cryptocurrencies = (Object.keys(currencies) as User["currency"][]).filter(
  (currency) => currencies[currency].crypto,
);

export const transitions: Readonly<Record<Transition, string>> = {
  fade: "fade",
  positionTop: "position-top",
  scale: "scale",
  slideDown: "slide-down",
  slideLeft: "slide-left",
  slideTop: "slide-top",
  slideRight: "slide-right",
};

export const routeNames: Readonly<Dictionary<string>> = {
  emptyPage: "Empty",
  errorPage: "Error",
  formPage: "Form",
  depositBasePage: "Base deposit",
  depositCompletePage: "Complete deposit",
  depositCryptoAddressPage: "Crypto address",
  depositCryptoCoinPage: "Crypto coin",
  depositCryptoNetworkPage: "Crypto network",
  depositGroupPage: "Group deposit",
  depositListPage: "Deposit",
  withdrawalBasePage: "Base withdrawal",
  withdrawalConfirmPage: "Confirm withdrawal",
  withdrawalCompletePage: "Complete withdrawal",
  withdrawalGroupPage: "Group withdrawal",
  withdrawalListPage: "Withdrawal",
  withdrawalPreparePage: "Withdrawal prepare",
};

export const routeNamesV2: Readonly<Dictionary<string>> = {
  emptyPage: "Empty v2",
  errorPage: "Error v2",
  formPage: "Form v2",
  depositBonusDetailsPage: "Deposit bonus details v2",
  depositCompletePage: "Complete deposit v2",
  depositCryptoAddressPage: "Crypto address v2",
  depositCryptoListPage: "Crypto list v2",
  depositGroupBasePage: "Group base deposit v2",
  depositGroupListPage: "Group list deposit v2",
  depositGroupPage: "Group deposit v2",
  depositIndiaInvoicePage: "Indian deposit invoice v2",
  depositIndiaPage: "Indian deposit v2",
  depositHostToHostPage: "Deposit host to host v2",
  depositListPage: "Deposit v2",
  depositPeerToPeerInstructionPage: "Deposit peer to peer instruction v2",
  depositPeerToPeerInvoicePage: "Deposit peer to peer invoice v2",
  depositPeerToPeerListPage: "Deposit peer to peer list v2",
  depositPeerToPeerPage: "Deposit peer to peer v2",
  depositRedirectEazypePage: "Eazype deposit v2",
  depositRedirectIndiaHostToHostPage: "Indian host to host deposit v2",
  depositRedirectPage: "Redirect deposit v2",
  depositRedirectInstructionPage: "Redirect deposit instruction v2",
  previousPage: "Previous v2",
  withdrawalCompletePage: "Complete withdrawal v2",
  withdrawalConfirmPage: "Confirm withdrawal v2",
  withdrawalGroupBasePage: "Group base withdrawal v2",
  withdrawalGroupListPage: "Group list withdrawal v2",
  withdrawalGroupPage: "Group withdrawal v2",
  withdrawalListPage: "Withdrawal v2",
  withdrawalNigerianBanksPage: "Withdrawal nigerian banks v2",
  withdrawalRedirectPage: "Redirect withdrawal v2",
  withdrawalRedirectInstructionPage: "Redirect withdrawal instruction v2",
};
