import { datadogRum } from "@datadog/browser-rum";
import { storeToRefs } from "pinia";

import { useParentStore } from "@/entities/parent";
import type { User } from "@/entities/user";

export default {
  install: async () => {
    if (!import.meta.env.PROD) {
      return;
    }

    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: import.meta.env.VITE_DATADOG_SITE,
      service: import.meta.env.VITE_DATADOG_SERVICE,
      env: import.meta.env.MODE,
      version: import.meta.env.VITE_BUILD_VERSION || "1.0.0",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      usePartitionedCrossSiteSessionCookie: true,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
  },

  setDatadogUser: (user: User) => {
    const { parentData } = storeToRefs(useParentStore());

    datadogRum.setUser({
      id: String(parentData.value.userId || user.id_user),
      partner_key: String(parentData.value.partnerKey || user.partner_key),
      hash_id: String(user.hash_id),
    });
  },

  sendCustomMetric: (name: string, data: object) => {
    datadogRum.addAction(name, data);
  },

  sendCustomError(name: string) {
    datadogRum.addError(new Error(name));
  },
};
