import { CurrencyCode } from "@1win/frontend-currencies";

import type {
  Limits,
  PaymentGroup,
  PaymentIcon,
  PaymentMethod,
  PaymentMethodGroup,
  PaymentIconType,
} from "@/entities/payment-method";
import type { Theme } from "@/entities/theme";
import { routeNames, routeNamesV2 } from "@/shared/constants";

type CardViewKey =
  | "default"
  | "card"
  | "cardWalletOnly"
  | "india"
  | "cardMultipleType"
  | "p2p"
  | "pix"
  | "eazype"
  | "virtual"
  | "bankTransfer";

type CirclePayment = Extract<
  Payment,
  "btc" | "tether" | "tron" | "sbp_transgran_rabbit_tbank" | "sbp_transgran_rabbit_sber"
>;

type Payment = PaymentMethod["name"] | PaymentGroup["name"] | "none";

type PaymentGroupKey =
  | "bankCard"
  | "banksIndonesia"
  | "banksMalaysia"
  | "banksThailand"
  | "bankTransferNigeria"
  | "cash"
  | "crypto"
  | "nigerianCards"
  | "p2pKyrgyzstan"
  | "p2pUkraine"
  | "sbpTransgranGrouped";

export const cardViews: Readonly<Record<CardViewKey, number>> = {
  default: 0,
  card: 1,
  cardWalletOnly: 2,
  india: 3,
  cardMultipleType: 4,
  p2p: 5,
  pix: 6,
  eazype: 7,
  virtual: 8,
  bankTransfer: 9,
};

export const cardGroups: Readonly<Record<PaymentGroupKey, PaymentMethodGroup>> = {
  bankCard: "card_method",
  banksIndonesia: "online-indonesian-banks-grouped",
  banksMalaysia: "online-malaysian-banks-grouped",
  banksThailand: "online-thailand-banks-grouped",
  bankTransferNigeria: "bank_transfer_nigeria",
  cash: "cash_methods",
  crypto: "crypto",
  nigerianCards: "nigeria-cards-grouped",
  p2pKyrgyzstan: "p2p_methods_kyrgyzstan",
  p2pUkraine: "p2p_methods_ukraine",
  sbpTransgranGrouped: "sbp-transgran-grouped",
};

export const nigerianBankIcons: Readonly<Record<string, Record<Theme, string>>> = {
  "Access Bank Nigeria": {
    dark: "payment-methods/common/light/access-bank",
    light: "payment-methods/common/dark/access-bank",
  },
  "Fidelity Bank": {
    dark: "payment-methods/common/light/fidelity-bank",
    light: "payment-methods/common/dark/fidelity-bank",
  },
  "First Bank of Nigeria": {
    dark: "payment-methods/common/light/first-bank",
    light: "payment-methods/common/dark/first-bank",
  },
  "GTBank Plc": {
    dark: "payment-methods/common/light/gt-bank",
    light: "payment-methods/common/dark/gt-bank",
  },
  "Kuda Micro-finance Bank": {
    dark: "payment-methods/common/light/kuda-bank",
    light: "payment-methods/common/dark/kuda-bank",
  },
  "Stanbic IBTC Bank": {
    dark: "payment-methods/common/light/stanbic-bank",
    light: "payment-methods/common/dark/stanbic-bank",
  },
  "Sterling Bank": {
    dark: "payment-methods/common/light/sterling-bank",
    light: "payment-methods/common/dark/sterling-bank",
  },
  "Wema Bank": {
    dark: "payment-methods/common/light/wema-bank",
    light: "payment-methods/common/dark/wema-bank",
  },
  "Zenith Bank Plc": {
    dark: "payment-methods/common/light/zenith-bank",
    light: "payment-methods/common/dark/zenith-bank",
  },
};

export const paymentIcons: Readonly<PaymentIcon[]> = [
  {
    name: "1win_cash",
    path: {
      base: {
        dark: "payment-methods/common/dark/cash",
        light: "payment-methods/common/dark/cash",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "account_transfer_cis",
    path: {
      base: {
        dark: "payment-methods/common/light/account-transfer-cis",
        light: "payment-methods/common/dark/account-transfer-cis",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "airtm",
    path: {
      base: {
        dark: "payment-methods/common/dark/airtm",
        light: "payment-methods/common/dark/airtm",
      },
      card: {
        dark: "payment-methods/compact/dark/airtm",
        light: "payment-methods/compact/dark/airtm",
      },
      complete: {
        dark: "payment-methods/compact/dark/airtm",
        light: "payment-methods/compact/dark/airtm",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "alsuper",
    path: {
      base: {
        dark: "payment-methods/common/dark/alsuper",
        light: "payment-methods/common/dark/alsuper",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "apple_pay_paystack",
    path: {
      base: {
        dark: "payment-methods/common/light/apple-pay-paystack",
        light: "payment-methods/common/dark/apple-pay-paystack",
      },
      complete: {
        dark: "payment-methods/common/dark/apple-pay-paystack",
        light: "payment-methods/common/dark/apple-pay-paystack",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "american_express",
    path: {
      base: {
        dark: "payment-methods/common/dark/american-express",
        light: "payment-methods/common/dark/american-express",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "astropay",
    path: {
      base: {
        dark: "payment-methods/common/light/astropay",
        light: "payment-methods/common/dark/astropay",
      },
      card: {
        dark: "payment-methods/compact/light/astropay",
        light: "payment-methods/compact/dark/astropay",
      },
      complete: {
        dark: "payment-methods/compact/dark/astropay",
        light: "payment-methods/compact/dark/astropay",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "bancomer",
    path: {
      base: {
        dark: "payment-methods/common/dark/bancomer",
        light: "payment-methods/common/dark/bancomer",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "banorte",
    path: {
      base: {
        dark: "payment-methods/common/dark/banorte",
        light: "payment-methods/common/dark/banorte",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "bank_transfer_nigeria",
    path: {
      base: {
        dark: "payment-methods/common/dark/bank-transfer-nigeria",
        light: "payment-methods/common/dark/bank-transfer-nigeria",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "bank_transfer_onewin",
    path: {
      base: {
        dark: "payment-methods/common/dark/bank-transfer",
        light: "payment-methods/common/dark/bank-transfer",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "binance",
    path: {
      base: {
        dark: "payment-methods/common/dark/bnb",
        light: "payment-methods/common/dark/bnb",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "bitcoin_cash",
    path: {
      base: {
        dark: "payment-methods/common/dark/bitcoin-cash",
        light: "payment-methods/common/dark/bitcoin-cash",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "bnb",
    path: {
      base: {
        dark: "payment-methods/common/dark/bnb",
        light: "payment-methods/common/dark/bnb",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "bnb_bep20",
    path: {
      base: {
        dark: "payment-methods/common/dark/bnb-bep",
        light: "payment-methods/common/dark/bnb-bep",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "bnb_bsc",
    path: {
      base: {
        dark: "payment-methods/common/dark/bnb",
        light: "payment-methods/common/dark/bnb",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "bnb_dex",
    path: {
      base: {
        dark: "payment-methods/common/dark/bnb-dex",
        light: "payment-methods/common/dark/bnb-dex",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "bodega_aurrera",
    path: {
      base: {
        dark: "payment-methods/common/dark/bodega-aurrera",
        light: "payment-methods/common/dark/bodega-aurrera",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "btc",
    path: {
      base: {
        dark: "payment-methods/common/dark/bitcoin",
        light: "payment-methods/common/dark/bitcoin",
      },
      circle: {
        dark: "payment-methods/circle/circle-bitcoin",
        light: "payment-methods/circle/circle-bitcoin",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "btc_usd",
    path: {
      base: {
        dark: "payment-methods/common/dark/bitcoin",
        light: "payment-methods/common/dark/bitcoin",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "busd",
    path: {
      base: {
        dark: "payment-methods/common/dark/busd",
        light: "payment-methods/common/dark/busd",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "busd_bep20",
    path: {
      base: {
        dark: "payment-methods/common/dark/busd-bep",
        light: "payment-methods/common/dark/busd-bep",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "busd_bsc",
    path: {
      base: {
        dark: "payment-methods/common/dark/busd",
        light: "payment-methods/common/dark/busd",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "busd_erc20",
    path: {
      base: {
        dark: "payment-methods/common/dark/busd-erc",
        light: "payment-methods/common/dark/busd-erc",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "busd_eth",
    path: {
      base: {
        dark: "payment-methods/common/dark/busd",
        light: "payment-methods/common/dark/busd",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "calimax",
    path: {
      base: {
        dark: "payment-methods/common/dark/calimax",
        light: "payment-methods/common/dark/calimax",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card",
    path: {
      base: {
        dark: "payment-methods/common/light/card",
        light: "payment-methods/common/dark/card",
      },
      card: {
        dark: "payment-methods/compact/light/card",
        light: "payment-methods/compact/dark/card",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "card-p2p",
    path: {
      base: {
        dark: "payment-methods/common/light/card-ru",
        light: "payment-methods/common/dark/card-ru",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "card_paystack",
    path: {
      base: {
        dark: "payment-methods/common/light/card-paystack",
        light: "payment-methods/common/dark/card-paystack",
      },
      complete: {
        dark: "payment-methods/common/dark/card-paystack",
        light: "payment-methods/common/dark/card-paystack",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_mastercard",
    path: {
      base: {
        dark: "payment-methods/common/dark/mastercard",
        light: "payment-methods/common/dark/mastercard",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
      {
        country: "ng",
        transactions: ["deposit"],
      },
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_mastercard_nigeria",
    path: {
      base: {
        dark: "payment-methods/common/dark/mastercard",
        light: "payment-methods/common/dark/mastercard",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_method",
    path: {
      base: {
        dark: "payment-methods/common/light/card",
        light: "payment-methods/common/dark/card",
      },
      card: {
        dark: "payment-methods/compact/light/card",
        light: "payment-methods/compact/dark/card",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
      {
        country: "ng",
        transactions: ["deposit"],
      },
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_mir",
    path: {
      base: {
        dark: "payment-methods/common/dark/mir",
        light: "payment-methods/common/dark/mir",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_rub",
    path: {
      base: {
        dark: "payment-methods/common/light/card",
        light: "payment-methods/common/dark/card",
      },
      card: {
        dark: "payment-methods/compact/light/card",
        light: "payment-methods/compact/dark/card",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "card_sberpay",
    path: {
      base: {
        dark: "payment-methods/common/light/card-sberpay",
        light: "payment-methods/common/dark/card-sberpay",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_sberpay_one_click",
    path: {
      base: {
        dark: "payment-methods/common/dark/card-sberpay-one-click",
        light: "payment-methods/common/dark/card-sberpay-one-click",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_sbp",
    path: {
      base: {
        dark: "payment-methods/common/light/sbp",
        light: "payment-methods/common/dark/sbp",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_p2p_account",
    path: {
      base: {
        dark: "payment-methods/common/dark/mir",
        light: "payment-methods/common/dark/mir",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_sberpay_p2p",
    path: {
      base: {
        dark: "payment-methods/common/dark/sberpay",
        light: "payment-methods/common/dark/sberpay",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_sbp_p2p",
    path: {
      base: {
        dark: "payment-methods/common/light/sbp",
        light: "payment-methods/common/dark/sbp",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_verve_nigeria",
    path: {
      base: {
        dark: "payment-methods/common/light/verve",
        light: "payment-methods/common/dark/verve",
      },
      complete: {
        dark: "payment-methods/common/dark/verve",
        light: "payment-methods/common/dark/verve",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_visa",
    path: {
      base: {
        dark: "payment-methods/common/light/visa",
        light: "payment-methods/common/dark/visa",
      },
      complete: {
        dark: "payment-methods/common/dark/visa",
        light: "payment-methods/common/dark/visa",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
      {
        country: "ng",
        transactions: ["deposit"],
      },
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "card_visa_nigeria",
    path: {
      base: {
        dark: "payment-methods/common/light/visa",
        light: "payment-methods/common/dark/visa",
      },
      complete: {
        dark: "payment-methods/common/dark/visa",
        light: "payment-methods/common/dark/visa",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "cardano",
    path: {
      base: {
        dark: "payment-methods/common/dark/cardano",
        light: "payment-methods/common/dark/cardano",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "cash_methods",
    path: {
      base: {
        dark: "payment-methods/common/dark/cash",
        light: "payment-methods/common/dark/cash",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "circulo_k",
    path: {
      base: {
        dark: "payment-methods/common/dark/circle-k",
        light: "payment-methods/common/dark/circle-k",
      },
      complete: {
        dark: "payment-methods/common/light/circle-k",
        light: "payment-methods/common/dark/circle-k",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "city_club",
    path: {
      base: {
        dark: "payment-methods/common/light/city-club",
        light: "payment-methods/common/dark/city-club",
      },
      complete: {
        dark: "payment-methods/common/dark/city-club",
        light: "payment-methods/common/dark/city-club",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "codi_mexico",
    path: {
      base: {
        dark: "payment-methods/common/light/codi",
        light: "payment-methods/common/dark/codi",
      },
      complete: {
        dark: "payment-methods/common/dark/codi",
        light: "payment-methods/common/dark/codi",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "colombian_banks",
    path: {
      base: {
        dark: null,
        light: null,
      },
    },
    supportedEntities: [],
  },
  {
    name: "comercial_mexicana",
    path: {
      base: {
        dark: "payment-methods/common/light/comercial-mexicana",
        light: "payment-methods/common/dark/comercial-mexicana",
      },
      complete: {
        dark: "payment-methods/common/dark/comercial-mexicana",
        light: "payment-methods/common/dark/comercial-mexicana",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "crypto",
    path: {
      base: {
        dark: "payment-methods/common/dark/bitcoin",
        light: "payment-methods/common/dark/bitcoin",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "dai",
    path: {
      base: {
        dark: "payment-methods/common/dark/dai",
        light: "payment-methods/common/dark/dai",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "dash",
    path: {
      base: {
        dark: "payment-methods/common/dark/dash",
        light: "payment-methods/common/dark/dash",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "doge",
    path: {
      base: {
        dark: "payment-methods/common/dark/doge",
        light: "payment-methods/common/dark/doge",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "el_asturiano",
    path: {
      base: {
        dark: "payment-methods/common/dark/asturiano",
        light: "payment-methods/common/dark/asturiano",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "e-money",
    path: {
      base: {
        dark: null,
        light: null,
      },
    },
    supportedEntities: [],
  },
  {
    name: "eos",
    path: {
      base: {
        dark: "payment-methods/common/light/eos",
        light: "payment-methods/common/dark/eos",
      },
      complete: {
        dark: "payment-methods/common/dark/eos",
        light: "payment-methods/common/dark/eos",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "eth",
    path: {
      base: {
        dark: "payment-methods/common/light/ethereum",
        light: "payment-methods/common/dark/ethereum",
      },
      complete: {
        dark: "payment-methods/common/dark/ethereum",
        light: "payment-methods/common/dark/ethereum",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "eth_usd",
    path: {
      base: {
        dark: "payment-methods/common/light/ethereum",
        light: "payment-methods/common/dark/ethereum",
      },
      complete: {
        dark: "payment-methods/common/dark/ethereum",
        light: "payment-methods/common/dark/ethereum",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "eziopay-crypto",
    path: {
      base: {
        dark: "payment-methods/common/dark/eziopay",
        light: "payment-methods/common/dark/eziopay",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "farmacias_benavides",
    path: {
      base: {
        dark: "payment-methods/common/dark/farmacias-benavides",
        light: "payment-methods/common/dark/farmacias-benavides",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "farmacias_del_ahorro",
    path: {
      base: {
        dark: "payment-methods/common/dark/farmacias-del-ahorro",
        light: "payment-methods/common/dark/farmacias-del-ahorro",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "farmacias_la_mas_barata",
    path: {
      base: {
        dark: "payment-methods/common/dark/farmacias-la-mas-barata",
        light: "payment-methods/common/dark/farmacias-la-mas-barata",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "farmacias_roma",
    path: {
      base: {
        dark: "payment-methods/common/light/farmacias-roma",
        light: "payment-methods/common/dark/farmacias-roma",
      },
      complete: {
        dark: "payment-methods/common/dark/farmacias-roma",
        light: "payment-methods/common/dark/farmacias-roma",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "fk_wallet",
    path: {
      base: {
        dark: "payment-methods/common/dark/fk-wallet",
        light: "payment-methods/common/dark/fk-wallet",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "gpay_einpayz",
    path: {
      base: {
        dark: "payment-methods/common/light/gpay",
        light: "payment-methods/common/dark/gpay",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "gpay_onewin",
    path: {
      base: {
        dark: "payment-methods/common/light/gpay",
        light: "payment-methods/common/dark/gpay",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "gpay_qr",
    path: {
      base: {
        dark: "payment-methods/common/light/gpay-eazype",
        light: "payment-methods/common/dark/gpay-eazype",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "gpay_vpa",
    path: {
      base: {
        dark: "payment-methods/common/light/gpay",
        light: "payment-methods/common/dark/gpay",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "kiosko",
    path: {
      base: {
        dark: "payment-methods/common/dark/kiosko",
        light: "payment-methods/common/dark/kiosko",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "indian-methods-grouped",
    path: {
      base: {
        dark: null,
        light: null,
      },
    },
    supportedEntities: [],
  },
  {
    name: "imps",
    path: {
      base: {
        dark: "payment-methods/common/light/imps",
        light: "payment-methods/common/dark/imps",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "imps_eazepe",
    path: {
      base: {
        dark: "payment-methods/common/light/imps",
        light: "payment-methods/common/dark/imps",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "imps_einpayz",
    path: {
      base: {
        dark: "payment-methods/common/light/imps",
        light: "payment-methods/common/dark/imps",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "litecoin",
    path: {
      base: {
        dark: "payment-methods/common/dark/litecoin",
        light: "payment-methods/common/dark/litecoin",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "monero",
    path: {
      base: {
        dark: "payment-methods/common/dark/monero",
        light: "payment-methods/common/dark/monero",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "money_go",
    path: {
      base: {
        dark: "payment-methods/common/light/money-go",
        light: "payment-methods/common/dark/money-go",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "muchbetter",
    path: {
      base: {
        dark: "payment-methods/common/dark/muchbetter",
        light: "payment-methods/common/dark/muchbetter",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "nem",
    path: {
      base: {
        dark: "payment-methods/common/dark/nem",
        light: "payment-methods/common/dark/nem",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "neo",
    path: {
      base: {
        dark: "payment-methods/common/dark/neo",
        light: "payment-methods/common/dark/neo",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "none",
    path: {
      base: {
        dark: null,
        light: null,
      },
    },
    supportedEntities: [],
  },
  {
    name: "nigerian_bank_transfer",
    path: {
      base: {
        dark: "payment-methods/common/dark/bank-transfer-main",
        light: "payment-methods/common/dark/bank-transfer-main",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "nigerian_bank_transfer_paystack",
    path: {
      base: {
        dark: "payment-methods/common/dark/bank-transfer-paystack",
        light: "payment-methods/common/dark/bank-transfer-paystack",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "nigerian_banks_paystack",
    path: {
      base: {
        dark: "payment-methods/common/dark/bank-paystack",
        light: "payment-methods/common/dark/bank-paystack",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "nigeria-cards-grouped",
    path: {
      base: {
        dark: "payment-methods/common/light/card",
        light: "payment-methods/common/dark/card",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "okt_okc_rub",
    path: {
      base: {
        dark: "payment-methods/common/light/card-ru",
        light: "payment-methods/common/dark/card-ru",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "online-indonesian-banks-grouped",
    path: {
      base: {
        dark: null,
        light: null,
      },
    },
    supportedEntities: [],
  },
  {
    name: "online-malaysian-banks-grouped",
    path: {
      base: {
        dark: null,
        light: null,
      },
    },
    supportedEntities: [],
  },
  {
    name: "online-scotiabank",
    path: {
      base: {
        dark: "payment-methods/common/dark/scotiabank",
        light: "payment-methods/common/dark/scotiabank",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "online-thailand-banks-grouped",
    path: {
      base: {
        dark: null,
        light: null,
      },
    },
    supportedEntities: [],
  },
  {
    name: "opay_paystack",
    path: {
      base: {
        dark: "payment-methods/common/light/opay-paystack",
        light: "payment-methods/common/dark/opay-paystack",
      },
      complete: {
        dark: "payment-methods/common/dark/opay-paystack",
        light: "payment-methods/common/dark/opay-paystack",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "oxxo_pay_h2h",
    path: {
      base: {
        dark: "payment-methods/common/dark/oxxo",
        light: "payment-methods/common/dark/oxxo",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "p2p_market",
    path: {
      base: {
        dark: "payment-methods/common/light/p2p-market",
        light: "payment-methods/common/dark/p2p-market",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "p2p_methods_kyrgyzstan",
    path: {
      base: {
        dark: null,
        light: null,
      },
    },
    supportedEntities: [],
  },
  {
    name: "p2p_methods_ukraine",
    path: {
      base: {
        dark: null,
        light: null,
      },
    },
    supportedEntities: [],
  },
  {
    name: "payattitude_paystack",
    path: {
      base: {
        dark: "payment-methods/common/dark/pay-attitude-paystack",
        light: "payment-methods/common/dark/pay-attitude-paystack",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "paytgstar",
    path: {
      base: {
        dark: "payment-methods/common/dark/tg-star",
        light: "payment-methods/common/dark/tg-star",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "pay_attitude",
    path: {
      base: {
        dark: "payment-methods/common/dark/pay-attitude",
        light: "payment-methods/common/dark/pay-attitude",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "payout_by_sber",
    path: {
      base: {
        dark: "payment-methods/common/dark/sber",
        light: "payment-methods/common/dark/sber",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "payout_by_t_bank",
    path: {
      base: {
        dark: "payment-methods/common/dark/t-bank",
        light: "payment-methods/common/dark/t-bank",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "paytm",
    path: {
      base: {
        dark: "payment-methods/common/light/paytm",
        light: "payment-methods/common/dark/paytm",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "paytm_h2h",
    path: {
      base: {
        dark: "payment-methods/common/light/paytm",
        light: "payment-methods/common/dark/paytm",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "paytm_onewin",
    path: {
      base: {
        dark: "payment-methods/common/light/paytm",
        light: "payment-methods/common/dark/paytm",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "paytm_qr",
    path: {
      base: {
        dark: "payment-methods/common/light/paytm-eazype",
        light: "payment-methods/common/dark/paytm-eazype",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "paytm_vpa",
    path: {
      base: {
        dark: "payment-methods/common/light/paytm",
        light: "payment-methods/common/dark/paytm",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "phone_p2p",
    path: {
      base: {
        dark: "payment-methods/common/light/sbp",
        light: "payment-methods/common/dark/sbp",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "phone_pe",
    path: {
      base: {
        dark: "payment-methods/compact/dark/phonepe",
        light: "payment-methods/compact/dark/phonepe",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "phone_pe_h2h",
    path: {
      base: {
        dark: "payment-methods/compact/dark/phonepe",
        light: "payment-methods/compact/dark/phonepe",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "phone_pe_qr",
    path: {
      base: {
        dark: "payment-methods/common/dark/phonepe-eazype",
        light: "payment-methods/common/dark/phonepe-eazype",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "phone_pe_vpa",
    path: {
      base: {
        dark: "payment-methods/common/light/phonepe",
        light: "payment-methods/common/dark/phonepe",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "phonepe_onewin",
    path: {
      base: {
        dark: "payment-methods/compact/dark/phonepe",
        light: "payment-methods/compact/dark/phonepe",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "piastrix_rub",
    path: {
      base: {
        dark: "payment-methods/common/dark/piastrix",
        light: "payment-methods/common/dark/piastrix",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "ripple",
    path: {
      base: {
        dark: "payment-methods/common/light/xrp",
        light: "payment-methods/common/dark/xrp",
      },
      complete: {
        dark: "payment-methods/common/dark/xrp",
        light: "payment-methods/common/dark/xrp",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "sams_club",
    path: {
      base: {
        dark: "payment-methods/common/light/sams-club",
        light: "payment-methods/common/dark/sams-club",
      },
      complete: {
        dark: "payment-methods/common/dark/sams-club",
        light: "payment-methods/common/dark/sams-club",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "santander_cash",
    path: {
      base: {
        dark: "payment-methods/common/dark/santander-cash",
        light: "payment-methods/common/dark/santander-cash",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "sberpay_paylink",
    path: {
      base: {
        dark: "payment-methods/common/dark/sberpay",
        light: "payment-methods/common/dark/sberpay",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "sbp_transgran_rabbit_sber",
    path: {
      base: {
        dark: "payment-methods/common/dark/sber",
        light: "payment-methods/common/dark/sber",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "sbp_transgran_rabbit_tbank",
    path: {
      base: {
        dark: "payment-methods/common/dark/t-bank",
        light: "payment-methods/common/dark/t-bank",
      },
      circle: {
        dark: "payment-methods/circle/circle-t-bank",
        light: "payment-methods/circle/circle-t-bank",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "seven_eleven",
    path: {
      base: {
        dark: "payment-methods/common/dark/seven-eleven",
        light: "payment-methods/common/dark/seven-eleven",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "short-nigerian-banks",
    path: {
      base: {
        dark: "payment-methods/common/dark/bank-transfer-main",
        light: "payment-methods/common/dark/bank-transfer-main",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "skypay",
    path: {
      base: {
        dark: "payment-methods/common/light/skypay",
        light: "payment-methods/common/dark/skypay",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "soriana",
    path: {
      base: {
        dark: "payment-methods/common/dark/soriana",
        light: "payment-methods/common/dark/soriana",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "spei",
    path: {
      base: {
        dark: "payment-methods/common/dark/spei",
        light: "payment-methods/common/dark/spei",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "spei_full",
    path: {
      base: {
        dark: "payment-methods/common/dark/spei",
        light: "payment-methods/common/dark/spei",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "spei_mexico",
    path: {
      base: {
        dark: "payment-methods/common/dark/spei",
        light: "payment-methods/common/dark/spei",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "spei_multi",
    path: {
      base: {
        dark: "payment-methods/common/dark/spei",
        light: "payment-methods/common/dark/spei",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "spei_unlimint",
    path: {
      base: {
        dark: "payment-methods/common/dark/spei",
        light: "payment-methods/common/dark/spei",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "steam_coin",
    path: {
      base: {
        dark: "payment-methods/common/light/steam",
        light: "payment-methods/common/dark/steam",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["withdrawal"],
      },
    ],
  },
  {
    name: "stellar",
    path: {
      base: {
        dark: "payment-methods/common/light/stellar",
        light: "payment-methods/common/dark/stellar",
      },
      complete: {
        dark: "payment-methods/common/dark/stellar",
        light: "payment-methods/common/dark/stellar",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "super_norte",
    path: {
      base: {
        dark: "payment-methods/common/dark/super-norte",
        light: "payment-methods/common/dark/super-norte",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "superama",
    path: {
      base: {
        dark: "payment-methods/common/dark/superama",
        light: "payment-methods/common/dark/superama",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "tapbank_namba",
    path: {
      base: {
        dark: "payment-methods/common/dark/t-bank",
        light: "payment-methods/common/dark/t-bank",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "telecomm",
    path: {
      base: {
        dark: "payment-methods/common/light/telecomm",
        light: "payment-methods/common/dark/telecomm",
      },
      complete: {
        dark: "payment-methods/common/dark/telecomm",
        light: "payment-methods/common/dark/telecomm",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "tether",
    path: {
      base: {
        dark: "payment-methods/common/dark/tether",
        light: "payment-methods/common/dark/tether",
      },
      circle: {
        dark: "payment-methods/circle/circle-tether",
        light: "payment-methods/circle/circle-tether",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "tether_bep20",
    path: {
      base: {
        dark: "payment-methods/common/dark/tether-bep",
        light: "payment-methods/common/dark/tether-bep",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "tether_erc20",
    path: {
      base: {
        dark: "payment-methods/common/dark/tether-erc",
        light: "payment-methods/common/dark/tether-erc",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "tether_omni",
    path: {
      base: {
        dark: "payment-methods/common/dark/tether-omni",
        light: "payment-methods/common/dark/tether-omni",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "tether_trc20",
    path: {
      base: {
        dark: "payment-methods/common/dark/tether-trc",
        light: "payment-methods/common/dark/tether-trc",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "terminals",
    path: {
      base: {
        dark: "payment-methods/common/dark/terminals",
        light: "payment-methods/common/dark/terminals",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "tiendas_extra",
    path: {
      base: {
        dark: "payment-methods/common/dark/tiendas-extra",
        light: "payment-methods/common/dark/tiendas-extra",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "todito",
    path: {
      base: {
        dark: "payment-methods/common/dark/todito",
        light: "payment-methods/common/dark/todito",
      },
      card: {
        dark: "payment-methods/compact/dark/todito",
        light: "payment-methods/compact/dark/todito",
      },
      complete: {
        dark: "payment-methods/compact/dark/todito",
        light: "payment-methods/compact/dark/todito",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "ton",
    path: {
      base: {
        dark: "payment-methods/common/dark/ton",
        light: "payment-methods/common/dark/ton",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "tpay_one_click",
    path: {
      base: {
        dark: "payment-methods/common/dark/t-pay",
        light: "payment-methods/common/dark/t-pay",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "transfer_by_sber",
    path: {
      base: {
        dark: "payment-methods/common/dark/transfer-by-sber",
        light: "payment-methods/common/dark/transfer-by-sber",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "transfer_by_sber_redirect",
    path: {
      base: {
        dark: "payment-methods/common/dark/transfer-by-sber",
        light: "payment-methods/common/dark/transfer-by-sber",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "tron",
    path: {
      base: {
        dark: "payment-methods/common/dark/tron",
        light: "payment-methods/common/dark/tron",
      },
      circle: {
        dark: "payment-methods/circle/circle-tron",
        light: "payment-methods/circle/circle-tron",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "upi",
    path: {
      base: {
        dark: "payment-methods/common/light/upi",
        light: "payment-methods/common/dark/upi",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "upi_h2h",
    path: {
      base: {
        dark: "payment-methods/common/light/upi",
        light: "payment-methods/common/dark/upi",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "upi_onewin",
    path: {
      base: {
        dark: "payment-methods/common/light/upi",
        light: "payment-methods/common/dark/upi",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "upi_short",
    path: {
      base: {
        dark: "payment-methods/common/light/upi",
        light: "payment-methods/common/dark/upi",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "upi_qr",
    path: {
      base: {
        dark: "payment-methods/common/light/upi-eazype",
        light: "payment-methods/common/dark/upi-eazype",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "upi_vpa",
    path: {
      base: {
        dark: "payment-methods/common/light/upi",
        light: "payment-methods/common/dark/upi",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "usdc",
    path: {
      base: {
        dark: "payment-methods/common/dark/usdc",
        light: "payment-methods/common/dark/usdc",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "usdc_bep20",
    path: {
      base: {
        dark: "payment-methods/common/dark/usdc-bep",
        light: "payment-methods/common/dark/usdc-bep",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "usdc_bsc",
    path: {
      base: {
        dark: "payment-methods/common/dark/usdc",
        light: "payment-methods/common/dark/usdc",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "usdc_erc20",
    path: {
      base: {
        dark: "payment-methods/common/dark/usdc-erc",
        light: "payment-methods/common/dark/usdc-erc",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "usdc_eth",
    path: {
      base: {
        dark: "payment-methods/common/dark/usdc",
        light: "payment-methods/common/dark/usdc",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "usdc_trc20",
    path: {
      base: {
        dark: "payment-methods/common/dark/usdc-trc",
        light: "payment-methods/common/dark/usdc-trc",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "usdc_trx",
    path: {
      base: {
        dark: "payment-methods/common/dark/usdc",
        light: "payment-methods/common/dark/usdc",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "ussd_paystack",
    path: {
      base: {
        dark: "payment-methods/common/dark/ussd-paystack",
        light: "payment-methods/common/dark/ussd-paystack",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "visa_qr_paystack",
    path: {
      base: {
        dark: "payment-methods/common/light/visa-qr-paystack",
        light: "payment-methods/common/dark/visa-qr-paystack",
      },
      complete: {
        dark: "payment-methods/common/dark/visa-qr-paystack",
        light: "payment-methods/common/dark/visa-qr-paystack",
      },
    },
    supportedEntities: [
      {
        country: "ng",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "vtb_one_click",
    path: {
      base: {
        dark: "payment-methods/common/light/vtb",
        light: "payment-methods/common/dark/vtb",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "waldos",
    path: {
      base: {
        dark: "payment-methods/common/dark/waldos",
        light: "payment-methods/common/dark/waldos",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "walmart",
    path: {
      base: {
        dark: "payment-methods/common/dark/walmart",
        light: "payment-methods/common/dark/walmart",
      },
    },
    supportedEntities: [
      {
        country: "mx",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "waves",
    path: {
      base: {
        dark: "payment-methods/common/dark/waves",
        light: "payment-methods/common/dark/waves",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "yamoney_rub",
    path: {
      base: {
        dark: "payment-methods/common/dark/yoo-money",
        light: "payment-methods/common/dark/yoo-money",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
  {
    name: "yamoney_h2h",
    path: {
      base: {
        dark: "payment-methods/common/dark/yoo-money",
        light: "payment-methods/common/dark/yoo-money",
      },
    },
    supportedEntities: [
      {
        country: "ru",
        transactions: ["deposit"],
      },
    ],
  },
  {
    name: "zcash",
    path: {
      base: {
        dark: "payment-methods/common/dark/zcash",
        light: "payment-methods/common/dark/zcash",
      },
    },
    supportedEntities: [
      {
        country: "in",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "mx",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ng",
        transactions: ["deposit", "withdrawal"],
      },
      {
        country: "ru",
        transactions: ["deposit", "withdrawal"],
      },
    ],
  },
];

export const paymentIconTypes: PaymentIconType[] = ["base", "circle"];

export const circlePaymentIconsByGroup: Readonly<Partial<Record<ObjectValues<typeof cardGroups>, CirclePayment[]>>> = {
  [cardGroups.crypto]: ["btc", "tether", "tron"],
  [cardGroups.sbpTransgranGrouped]: ["sbp_transgran_rabbit_tbank", "sbp_transgran_rabbit_sber"],
};

export const depositBasePathsV2: Readonly<Partial<Record<ObjectValues<typeof cardViews>, string>>> = {
  [cardViews.india]: routeNamesV2.depositIndiaPage,
  [cardViews.p2p]: routeNamesV2.depositPeerToPeerPage,
};

export const withdrawalBasePathsV2: Readonly<Partial<Record<ObjectValues<typeof cardViews>, string>>> = {
  [cardViews.bankTransfer]: routeNamesV2.withdrawalNigerianBanksPage,
};

export const groupPaths: Readonly<Partial<Record<ObjectValues<typeof cardGroups>, string>>> = {
  [cardGroups.crypto]: routeNames.depositCryptoCoinPage,
};

export const groupPathsV2: Readonly<Partial<Record<ObjectValues<typeof cardGroups>, string>>> = {
  [cardGroups.crypto]: routeNamesV2.depositCryptoListPage,
  [cardGroups.p2pKyrgyzstan]: routeNamesV2.depositPeerToPeerListPage,
  [cardGroups.p2pUkraine]: routeNamesV2.depositPeerToPeerListPage,
  [cardGroups.sbpTransgranGrouped]: routeNamesV2.depositPeerToPeerListPage,
};

export const defaultLimits: Readonly<Limits> = {
  max: 0,
  min: 0,
  minNonRus: null,
};

export const limitsMock: Readonly<Limits> = {
  max: 500_000,
  min: 1_000,
  minNonRus: null,
};

export const paymentGroupMock: Readonly<PaymentGroup> = {
  label: "Cryptocurrency",
  name: "crypto",
  paymentMethods: [],
  position: 1,
};

export const paymentMethodMock: Readonly<PaymentMethod> = {
  category: "e-money",
  currency: [CurrencyCode.MXN],
  text: {
    en: "Airtm",
    ru: "Airtm",
    az: "Airtm",
    bd: "Airtm",
    cn: "Airtm",
    de: "Airtm",
    el: "Airtm",
    es: "Airtm",
    fr: "Airtm",
    id: "Airtm",
    in: "Airtm",
    it: "Airtm",
    jp: "Airtm",
    kg: "Airtm",
    ko: "Airtm",
    kz: "Airtm",
    md: "Airtm",
    mr: "Airtm",
    mx: "Airtm",
    pl: "Airtm",
    pt: "Airtm",
    tj: "Airtm",
    tr: "Airtm",
    ua: "Airtm",
    uz: "Airtm",
    za: "Airtm",
  },
  amount_rules: {
    [CurrencyCode.MXN]: {
      min: 21,
      max: 20155,
      minNonRus: null,
    },
  },
  name: "airtm",
  checkLuhn: 0,
  fields: [
    {
      mask: null,
      name: "amount",
      category: "airtm_deposit",
      regex: ".*",
      regex_error_key: "paymentMethods.regex_error.airtm_amount",
      placeholder_key: "paymentMethods.placeholder.airtm_amount",
      type: "amount",
      values: null,
    },
  ],
  isInvestForbidden: false,
  isPopular: false,
  isPreparationRequired: false,
  securityRulesGroup: "e-money",
  position: 62,
};

export const paymentMethodsWithV3Recaptcha: Readonly<string[]> = [
  "card-p2p",
  "card_sberpay_one_click",
  "card_sberpay_p2p",
  "card_p2p_uz_humo",
  "card_p2p_uz_uzcard",
  "phone_p2p",
  "sberpay_paylink",
  "transfer_by_sber",
  "sbp_transgran_rabbit_tbank",
  "sbp_transgran_rabbit_sber",
];
