import { helpers } from "@vuelidate/validators";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { i18n } from "@/app/plugins";
import type { IndiaHostToHostApiResponse, PrepareDepositField } from "@/entities/deposit";
import type {
  AdditionalField,
  AdditionalFieldValues,
  Field,
  FormField,
  NonAmountField,
  MappedField,
} from "@/entities/payment-method";
import type { ErrorRef } from "@/features/form";

const NAMESPACE = "form";

export const useFormStore = defineStore(NAMESPACE, () => {
  const amount = ref("");
  const fields = ref<FormField[]>([]);
  const errorRefs = ref<ErrorRef[]>([]);
  const isAmountValid = ref(true);
  const isFormValid = ref(true);

  const isFieldsValid = computed(() => fields.value.every((field) => field.props.value));
  const isSomeFieldTriggered = computed(() => fields.value.some((field) => field.props.isTriggered));

  const validationState = computed<Record<FormField["name"], FormField["props"]["value"]>>(() =>
    fields.value.reduce((acc, field) => ({ ...acc, [field.name]: field.props.value }), {}),
  );
  const validationRules = computed(() =>
    fields.value.reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: field.props.validations.map((validation, index) => ({
          [index]: helpers.withMessage(validation.message, () =>
            new RegExp(validation.regex, "u").test(String(field.props.value)),
          ),
        })),
      }),
      {},
    ),
  );

  const changeAmount = (value: string) => {
    amount.value = value;
  };

  const changeAmountValidity = (value: boolean) => {
    isAmountValid.value = value;
  };

  const changeFormValidity = (value: boolean) => {
    isFormValid.value = value;
  };

  const changeFields = (items: Field[]) => {
    const filteredItems = (items ?? []).filter((field) => field.type !== "amount") as NonAmountField[];
    setFields(mapFields(mapBaseFields(filteredItems)));
  };

  const changeFieldProps = (name: FormField["name"], payload: Partial<FormField["props"]>) => {
    const index = fields.value.findIndex((field) => field.name === name);
    setFields([
      ...fields.value.slice(0, index),
      { ...fields.value[index], props: { ...fields.value[index].props, ...payload } },
      ...fields.value.slice(index + 1),
    ]);
  };

  const getFieldEvents = (field: MappedField) => {
    const commonEvents = {
      change: (value: string | number) =>
        changeFieldProps(field.name, {
          isTriggered: true,
          value,
        }),
      focusin: () =>
        changeFieldProps(field.name, {
          validations: field.validations.map((validation) => ({ ...validation, isExists: false })),
        }),
    };
    const events: Record<FormField["type"], FormField["events"]> = {
      dropdown: {
        ...commonEvents,
      },
      input_with_tooltip: {
        ...commonEvents,
        input: (event: Event & { target: HTMLInputElement }) => {
          // commented during the task BILLING-4948
          // sendInputFieldEvent({ form_type: "wait", field_name: field.name });

          changeFieldProps(field.name, {
            isTriggered: true,
            value: event.target.value,
          });
        },
      },
      text: {
        ...commonEvents,
        input: (event: Event & { target: HTMLInputElement }) => {
          // commented during the task BILLING-4948
          // sendInputFieldEvent({ form_type: "wait", field_name: field.name });

          changeFieldProps(field.name, {
            isTriggered: true,
            value: event.target.value,
          });
        },
      },
      text_uppercase: {
        ...commonEvents,
        input: (event: Event & { target: HTMLInputElement }) => {
          // commented during the task BILLING-4948
          // sendInputFieldEvent({ form_type: "wait", field_name: field.name });

          changeFieldProps(field.name, {
            isTriggered: true,
            value: event.target.value.toUpperCase(),
          });
        },
      },
      networks: {},
    };
    return events[field.type];
  };

  const getFieldProps = (field: MappedField) => {
    const commonProps = {
      isAdvanced: field.isAdvanced,
      isTriggered: false,
      label:
        i18n.global.t(field.placeholder_key) ||
        field.placeholder_text?.[i18n.global.locale.value] ||
        field.placeholder_text?.en ||
        "",
      mask: field.mask,
      validations: field.validations,
      value: field.value ?? "",
    };
    const props: Record<FormField["type"], FormField["props"]> = {
      dropdown: {
        ...commonProps,
        items: normalizeValues(field.values),
      },
      input_with_tooltip: {
        ...commonProps,
        isDisabled: false,
      },
      text: {
        ...commonProps,
        isDisabled: false,
      },
      text_uppercase: {
        ...commonProps,
        isDisabled: false,
      },
      networks: {
        ...commonProps,
        isDisabled: false,
      },
    };
    return props[field.type];
  };

  const mapAdditionalFields = (fields: AdditionalField[]): MappedField[] => {
    return fields.map((field) => ({
      category: "",
      isAdvanced: field.isAdvanced,
      mask: field.mask,
      name: field.name,
      placeholder_key: field.placeholderKey,
      placeholder_text: {},
      type: field.type === "dropdown" ? field.type : "text",
      validations: field.regexList.map((item) => ({
        isExists: false,
        message: i18n.global.t(item.regex_error_key),
        regex: item.regex,
      })),
      value: field.value,
      values: field.values ?? [],
    }));
  };

  const mapBaseFields = (fields: NonAmountField[]): MappedField[] => {
    return fields.map((field) => ({
      ...field,
      type: field.type ?? "text",
      validations: [
        {
          isExists: false,
          message: i18n.global.t(field.regex_error_key),
          regex: field.regex,
        },
      ],
    }));
  };

  const mapFields = (fields: MappedField[]): FormField[] => {
    return fields.map((item) => ({
      events: getFieldEvents(item),
      name: item.name,
      props: getFieldProps(item),
      type: item.type,
    }));
  };

  const mapInputFields = (fields: IndiaHostToHostApiResponse["inputFields"]): Field[] => {
    return fields.map((field) => ({
      category: "",
      mask: null,
      name: field.name,
      placeholder_key: field.placeholder,
      regex: field.pattern,
      regex_error_key: field.errorText,
      type: null,
      values: null,
    }));
  };

  const mapPreparationFields = (fields: PrepareDepositField[]): Field[] => {
    return fields.map((field) => ({
      category: "",
      mask: field.mask,
      name: field.name,
      placeholder_key: "",
      placeholder_text: {
        en: field.placeholder,
      },
      regex: field.regex,
      regex_error_key: field.regexError,
      type: null,
      values: null,
    }));
  };

  const normalizeValues = (values: MappedField<AdditionalFieldValues[] | string[]>["values"]) => {
    return (values ?? []).map((item) =>
      typeof item === "object"
        ? {
            text: item.label ?? item,
            value: item.value ?? item,
          }
        : {
            text: item,
            value: item,
          },
    );
  };

  const scrollToErrorRef = (items: ErrorRef[]) => {
    const filteredItems = items.filter((item) => item.hasError);

    if (!filteredItems.length) {
      return;
    }

    filteredItems[0].el.scrollIntoView({ behavior: "smooth" });
  };

  const setFields = (items: FormField[]) => {
    fields.value = items;
  };

  const setErrorRefs = (items: ErrorRef[]) => {
    errorRefs.value = items;
  };

  return {
    amount,
    fields,
    errorRefs,
    isAmountValid,
    isFormValid,
    isFieldsValid,
    isSomeFieldTriggered,
    validationState,
    validationRules,
    changeAmount,
    changeAmountValidity,
    changeFormValidity,
    changeFields,
    changeFieldProps,
    mapAdditionalFields,
    mapFields,
    mapInputFields,
    mapPreparationFields,
    scrollToErrorRef,
    setFields,
    setErrorRefs,
  };
});
