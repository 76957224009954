import { defineStore } from "pinia";
import { reactive } from "vue";

import type { ParentData } from "@/entities/parent";

const NAMESPACE = "parent";

type Data = Pick<
  ParentData["payload"],
  "closingButton" | "experiments" | "host" | "token" | "xOrigin" | "userId" | "partnerKey"
>;

export const useParentStore = defineStore(NAMESPACE, () => {
  const parentData = reactive<Data>({
    closingButton: true,
    experiments: [],
    host: "",
    token: "",
    xOrigin: "",
    userId: "",
    partnerKey: "",
  });

  const changeParentData = <K extends keyof Data>(key: K, value: Data[K]) => {
    parentData[key] = value;
  };

  return {
    parentData,
    changeParentData,
  };
});
