<script setup lang="ts">
import { computed } from "vue";

import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { PaymentGroupTileV2, PaymentMethodTileV2 } from "@/entities/payment-method";
import { SectionGrid } from "@/entities/section";
import type { Transaction } from "@/entities/transaction";

type Emits = {
  selectPaymentGroup: [paymentGroup: PaymentGroup];
  selectPaymentMethod: [paymentMethod: PaymentMethod];
};

interface Props {
  paymentGroup: Nullable<PaymentGroup>;
  paymentGroups: PaymentGroup[];
  paymentMethod: Nullable<PaymentMethod>;
  paymentMethods: PaymentMethod[];
  transaction: Transaction;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const sortedItems = computed(() =>
  [...props.paymentGroups, ...props.paymentMethods].sort((a, b) => a.position - b.position),
);

const onSelectPaymentGroup = (paymentGroup: PaymentGroup) => emit("selectPaymentGroup", paymentGroup);
const onSelectPaymentMethod = (paymentMethod: PaymentMethod) => emit("selectPaymentMethod", paymentMethod);
</script>

<template>
  <SectionGrid>
    <template
      v-for="item of sortedItems"
      :key="item.name"
    >
      <PaymentGroupTileV2
        v-if="'paymentMethods' in item"
        :class="$style.tile"
        :payment-group="item"
        :transaction="transaction"
        @click="onSelectPaymentGroup(item)"
      />
      <PaymentMethodTileV2
        v-else
        :class="$style.tile"
        :payment-method="item"
        :transaction="transaction"
        @click="onSelectPaymentMethod(item)"
      />
    </template>
  </SectionGrid>
</template>

<style module lang="postcss">
.tile {
  margin: var(--spacing-4);
}
</style>
