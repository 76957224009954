import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";

import type { Bonus } from "@/entities/bonus";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { useFormStore } from "@/features/form";

const NAMESPACE = "bonus";

export const useBonusStore = defineStore(NAMESPACE, () => {
  const { paymentMethod } = storeToRefs(usePaymentMethodStore());

  const { amount } = storeToRefs(useFormStore());

  const activeBonusId = ref<Bonus["id"]>();
  const bonuses = ref<Bonus[]>([]);
  const isBonusEnabled = ref(true);
  const isBonusDisplayed = ref(false);

  const activeBonus = computed<Bonus | undefined>(() =>
    bonuses.value.find((bonus) => bonus.id === activeBonusId.value),
  );
  const isBonusActivated = computed(() => isBonusSelected.value && isMinDepositReached.value);
  const isBonusSelected = computed(
    () => !isBonusRestrictedByPaymentMethod.value && isBonusEnabled.value && Boolean(activeBonusId.value),
  );
  const isBonusRestrictedByPaymentMethod = computed(() =>
    ["yamoney_h2h", "yamoney_rub", "webmoney_usd"].includes(paymentMethod.value?.name ?? ""),
  );
  const isMinDepositReached = computed(
    () =>
      Number(activeBonus.value?.depositBonusData?.minDeposit ?? activeBonus.value?.freespinBonusData?.minDeposit) <=
      Number(amount.value),
  );

  const changeActiveBonusId = (value?: Bonus["id"]) => {
    activeBonusId.value = value;
  };

  const changeBonuses = (data: Bonus[]) => {
    bonuses.value = data;
  };

  const changeBonusDisplayStatus = (value: boolean) => {
    isBonusDisplayed.value = value;
  };

  const toggleBonusStatus = (status: boolean) => {
    isBonusEnabled.value = status;
  };

  return {
    activeBonus,
    activeBonusId,
    bonuses,
    isBonusActivated,
    isBonusDisplayed,
    isBonusEnabled,
    isBonusSelected,
    isBonusRestrictedByPaymentMethod,
    changeActiveBonusId,
    changeBonuses,
    changeBonusDisplayStatus,
    toggleBonusStatus,
  };
});
