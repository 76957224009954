<script setup lang="ts">
import { storeToRefs } from "pinia";
import { nextTick, onBeforeMount, onBeforeUnmount, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { datadogPlugin } from "@/app/plugins";
import type { PaymentMethod } from "@/entities/payment-method";
import { filterPaymentMethodsByIcon, getAvailableCountries, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionView, SectionSpinner } from "@/entities/section";
import { useThemeStore } from "@/entities/theme";
import { DEPOSIT_EVENT_TOKEN, useTrackTime } from "@/entities/transaction";
import type { Snippet } from "@/entities/user";
import { useUserStore } from "@/entities/user";
import { useModalStore } from "@/features/modal";
import { routeNames } from "@/shared/constants";
import { fetchData, handlePageTransition } from "@/shared/lib";
import { bridgeService } from "@/shared/services";
import { Container } from "@/shared/ui";

const route = useRoute();
const router = useRouter();

const paymentMethodStore = usePaymentMethodStore();
const { isFetchingPaymentMethods, paymentMethodsResponse, paymentSnippetResponse } = storeToRefs(paymentMethodStore);
const { changeInitialPaymentMethods, changePaymentMethods, fetchPaymentMethods, fetchPaymentSnippet } =
  paymentMethodStore;

const userStore = useUserStore();
const { country } = storeToRefs(userStore);
const { changeSnippet } = userStore;

const { theme } = storeToRefs(useThemeStore());

const { track } = useTrackTime(DEPOSIT_EVENT_TOKEN);

const { changeIsOpen } = useModalStore();

const setData = (paymentMethods: Dictionary<PaymentMethod>) => {
  const methods = filterPaymentMethodsByIcon(paymentMethods, {
    country: country.value,
    theme: theme.value,
    transaction: "deposit",
  });

  changeInitialPaymentMethods(methods);
  changePaymentMethods(methods);
};

const setSnippetData = () => {
  changeSnippet(paymentSnippetResponse.value as Snippet);
};

const handler = async () => {
  await handlePageTransition(route, router, () => changeIsOpen(true));
};

onBeforeMount(async () => {
  const countries = getAvailableCountries();

  if (!countries.includes(country.value)) {
    await router.push({ name: routeNames.emptyPage });
    return;
  }

  bridgeService.attachEventListener("onGoBack", handler);
  bridgeService.attachEventListener("onSwipeBack", handler);

  fetchPaymentSnippet()
    .then(setSnippetData)
    .catch((error) => console.error(error));

  await fetchData([fetchPaymentMethods()], { back: routeNames.depositListPage, error: routeNames.errorPage }, router);

  setData(paymentMethodsResponse.value as Dictionary<PaymentMethod>);

  await track();
});

onBeforeUnmount(() => {
  bridgeService.detachEventListener("onGoBack", handler);
  bridgeService.detachEventListener("onSwipeBack", handler);
});

if (datadogPlugin && window.__appStartTime) {
  watch(
    () => [isFetchingPaymentMethods.value],
    async ([fetchingMethods]) => {
      if (!fetchingMethods) {
        await nextTick();

        datadogPlugin.sendCustomMetric("Time To Deposit Methods Render (dark theme)", {
          timeToDepositMethodsRenderDark: performance.now() - window.__appStartTime,
        });
      }
    },
  );
}
</script>

<template>
  <section>
    <Container>
      <SectionSpinner v-if="isFetchingPaymentMethods" />
      <SectionView v-else />
    </Container>
  </section>
</template>
