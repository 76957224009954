<script setup lang="ts">
import { storeToRefs } from "pinia";

import type { PaymentMethod } from "@/entities/payment-method";
import { PaymentMethodCardV2, PaymentMethodIcon, getPaymentMethodBadge } from "@/entities/payment-method";
import type { Transaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { Caption } from "@/shared/ui-v2";

interface Props {
  paymentMethod: PaymentMethod;
  transaction: Transaction;
}

defineProps<Props>();

const { user } = storeToRefs(useUserStore());
</script>

<template>
  <PaymentMethodCardV2
    :class="$style.root"
    :variant="getPaymentMethodBadge(paymentMethod)"
  >
    <PaymentMethodIcon
      :name="paymentMethod.name"
      size="l"
      :transaction="transaction"
      type="base"
    />
    <Caption
      :class="$style.label"
      size="s-sb"
    >
      {{ paymentMethod?.text[user.lang] ?? paymentMethod?.text.en }}
    </Caption>
  </PaymentMethodCardV2>
</template>

<style module lang="postcss">
.root {
  text-align: start;
}

.label {
  @add-mixin text-clamp-ellipsis;
}
</style>
