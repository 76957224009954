<script setup lang="ts">
import { computed } from "vue";

import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { PaymentGroupTile, PaymentMethodTile } from "@/entities/payment-method";
import type { Transaction } from "@/entities/transaction";

type Emits = {
  selectPaymentGroup: [paymentGroup: PaymentGroup];
  selectPaymentMethod: [paymentMethod: PaymentMethod];
};

interface Props {
  paymentGroup: Nullable<PaymentGroup>;
  paymentGroups: PaymentGroup[];
  paymentMethod: Nullable<PaymentMethod>;
  paymentMethods: PaymentMethod[];
  transaction: Transaction;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const sortedItems = computed(() =>
  [...props.paymentGroups, ...props.paymentMethods].sort((a, b) => a.position - b.position),
);

const onSelectPaymentGroup = (paymentGroup: PaymentGroup) => emit("selectPaymentGroup", paymentGroup);
const onSelectPaymentMethod = (paymentMethod: PaymentMethod) => emit("selectPaymentMethod", paymentMethod);
</script>

<template>
  <div class="grid grid-cols-2 content-baseline gap-2 sm:grid-cols-3 md:grid-cols-4">
    <template
      v-for="item of sortedItems"
      :key="item.name"
    >
      <PaymentGroupTile
        v-if="'paymentMethods' in item"
        :is-active="item.name === paymentGroup?.name"
        :payment-group="item"
        :transaction="transaction"
        @select="onSelectPaymentGroup"
      />
      <PaymentMethodTile
        v-else
        :is-active="item.name === paymentMethod?.name"
        :payment-method="item"
        size="lg"
        :transaction="transaction"
        @select="onSelectPaymentMethod"
      />
    </template>
  </div>
</template>
