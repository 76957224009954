import type { Component } from "vue";

const modules = import.meta.glob("@/shared/assets/icons/**/*.svg", {
  import: "default",
});

export const fetchIcon = async (name: string) => {
  try {
    const module = modules[`/src/shared/assets/icons/${name}.svg`];
    const promise = await module();
    return promise as Promise<Component>;
  } catch (error) {
    throw new Error(`Unable to fetch icon: ${name}`);
  }
};
