<script setup lang="ts">
import { computed } from "vue";

import type { PaymentGroup } from "@/entities/payment-method";
import {
  PaymentMethodCardV2,
  PaymentMethodGroupIcons,
  PaymentMethodIcon,
  circlePaymentIconsByGroup,
} from "@/entities/payment-method";
import type { Transaction } from "@/entities/transaction";
import { Caption } from "@/shared/ui-v2";

interface Props {
  paymentGroup: PaymentGroup;
  transaction: Transaction;
}

const props = defineProps<Props>();

const circleIcons = computed(() => circlePaymentIconsByGroup[props.paymentGroup.name] ?? []);
</script>

<template>
  <PaymentMethodCardV2 :class="$style.root">
    <PaymentMethodGroupIcons
      v-if="circleIcons.length"
      :icons="circleIcons"
      :payment-group="paymentGroup"
      :transaction="transaction"
    />
    <PaymentMethodIcon
      v-else
      :name="paymentGroup.name"
      size="l"
      :transaction="transaction"
      type="base"
    />
    <Caption
      :class="$style.label"
      size="s-sb"
    >
      {{ paymentGroup.label }}
    </Caption>
  </PaymentMethodCardV2>
</template>

<style module lang="postcss">
.root {
  text-align: start;
}

.label {
  @add-mixin text-clamp-ellipsis;
}
</style>
