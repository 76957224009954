<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount, onMounted } from "vue";
import { useRouter } from "vue-router";

import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { cardViews, depositBasePathsV2, groupPathsV2, usePaymentMethodStore } from "@/entities/payment-method";
import { defaultResponse, usePeerToPeerStore } from "@/entities/peer-to-peer";
import { SectionWrapperV2 } from "@/entities/section";
import { useAnalytics } from "@/features/analytics";
import { useFormStore, useChangeFormAmount } from "@/features/form";
import { routeNamesV2 } from "@/shared/constants";
import { PaymentSystemsV2 } from "@/widgets/transaction";

const router = useRouter();

const paymentMethodStore = usePaymentMethodStore();
const { initialPaymentMethods, paymentGroup, paymentMethod, filteredPaymentGroups, filteredPaymentMethods } =
  storeToRefs(paymentMethodStore);
const { changePaymentGroup, changePaymentMethod, changePaymentMethods } = paymentMethodStore;

const { changeResponse } = usePeerToPeerStore();

const { changeAmount, changeAmountValidity, changeFields, setFields } = useFormStore();

const { sendDepositFormViewEvent } = useAnalytics();

const { setFormAmount } = useChangeFormAmount();

const resetData = () => {
  changeAmount("");
  changeAmountValidity(true);
  changePaymentGroup(null);
  changePaymentMethod(null);
  changePaymentMethods(initialPaymentMethods.value);
  changeResponse(defaultResponse);
  setFields([]);
};

const selectPaymentGroup = async (paymentGroup: PaymentGroup) => {
  // commented during the task BILLING-4948
  // sendDepositSelectFieldEvent({ form_type: "card", field_value: paymentGroup.name });

  changePaymentGroup(paymentGroup);
  changePaymentMethods(Object.values(paymentGroup.paymentMethods));

  await router.push({
    name: groupPathsV2[paymentGroup.name] ?? routeNamesV2.depositGroupListPage,
  });
};

const selectPaymentMethod = async (paymentMethod: PaymentMethod) => {
  // commented during the task BILLING-4948
  // sendDepositSelectFieldEvent({ form_type: "method", field_value: paymentMethod.name });

  changePaymentMethod(paymentMethod);
  changeFields(paymentMethod.fields);

  setFormAmount();
  await router.push({
    name: depositBasePathsV2[paymentMethod.cardViewType ?? cardViews.default] ?? routeNamesV2.depositRedirectPage,
  });
};

onMounted(() => sendDepositFormViewEvent({ form_type: "method" }));

onBeforeMount(resetData);
</script>

<template>
  <SectionWrapperV2 :class="$style.root">
    <PaymentSystemsV2
      :payment-group="paymentGroup"
      :payment-groups="filteredPaymentGroups"
      :payment-method="paymentMethod"
      :payment-methods="filteredPaymentMethods"
      transaction="deposit"
      @select-payment-group="selectPaymentGroup"
      @select-payment-method="selectPaymentMethod"
    />
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.root {
  @add-mixin scrollbar-hidden;

  padding-top: 2px;
  overflow-y: auto;
}
</style>
