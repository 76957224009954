import type { RouteRecordRaw } from "vue-router";

import DepositListPage from "@/pages/deposit/list/deposit-list-page.vue";
import DepositPage from "@/pages/deposit/view/deposit-page.vue";
import DepositListPageV2 from "@/pages/v2/deposit/list/deposit-list-page.vue";
import DepositPageV2 from "@/pages/v2/deposit/view/deposit-page.vue";
import { routeNames, routeNamesV2 } from "@/shared/constants";
import { paymentMethodMiddleware, performanceMarksMiddleware } from "@/shared/routing";

export const routes: RouteRecordRaw[] = [
  {
    path: "",
    redirect: "/deposit",
  },
  {
    path: "/deposit",
    component: DepositPage,
    beforeEnter: [performanceMarksMiddleware],
    children: [
      {
        path: "",
        component: DepositListPage,
        name: routeNames.depositListPage,
        meta: {
          depth: 0,
          transaction: "deposit",
        },
      },
      {
        path: "base",
        component: () => import("@/pages/deposit/base/deposit-base-page.vue"),
        name: routeNames.depositBasePage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          backRoute: routeNames.depositListPage,
          depth: 1,
          transaction: "deposit",
        },
      },
      {
        path: "crypto",
        component: () => import("@/pages/deposit/crypto/view/deposit-crypto-page.vue"),
        beforeEnter: [paymentMethodMiddleware],
        children: [
          {
            path: "",
            component: () => import("@/pages/deposit/crypto/coin/deposit-crypto-coin-page.vue"),
            name: routeNames.depositCryptoCoinPage,
            meta: {
              backRoute: routeNames.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            path: "network",
            component: () => import("@/pages/deposit/crypto/network/deposit-crypto-network-page.vue"),
            name: routeNames.depositCryptoNetworkPage,
            meta: {
              backRoute: routeNames.depositCryptoCoinPage,
              depth: 2,
              transaction: "deposit",
            },
          },
          {
            path: "address",
            component: () => import("@/pages/deposit/crypto/address/deposit-crypto-address-page.vue"),
            name: routeNames.depositCryptoAddressPage,
            meta: {
              backRoute: routeNames.depositCryptoNetworkPage,
              depth: 3,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        path: "group",
        component: () => import("@/pages/deposit/group/deposit-group-page.vue"),
        name: routeNames.depositGroupPage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          backRoute: routeNames.depositListPage,
          depth: 1,
          transaction: "deposit",
        },
      },
      {
        path: "complete",
        component: () => import("@/pages/deposit/complete/deposit-complete-page.vue"),
        name: routeNames.depositCompletePage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          depth: 4,
          transaction: "deposit",
        },
      },
    ],
  },
  {
    path: "/withdrawal",
    component: () => import("@/pages/withdrawal/view/withdrawal-page.vue"),
    beforeEnter: [performanceMarksMiddleware],
    children: [
      {
        path: "",
        component: () => import("@/pages/withdrawal/list/withdrawal-list-page.vue"),
        name: routeNames.withdrawalListPage,
        meta: {
          depth: 1,
          transaction: "withdrawal",
        },
      },
      {
        path: "prepare",
        component: () => import("@/pages/withdrawal/prepare/withdrawal-prepare-page.vue"),
        name: routeNames.withdrawalPreparePage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          depth: 0,
          transaction: "withdrawal",
          transition: "none",
        },
      },
      {
        path: "base",
        component: () => import("@/pages/withdrawal/base/withdrawal-base-page.vue"),
        name: routeNames.withdrawalBasePage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          backRoute: routeNames.withdrawalListPage,
          depth: 2,
          transaction: "withdrawal",
        },
      },
      {
        path: "group",
        component: () => import("@/pages/withdrawal/group/withdrawal-group-page.vue"),
        name: routeNames.withdrawalGroupPage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          backRoute: routeNames.withdrawalListPage,
          depth: 2,
          transaction: "withdrawal",
        },
      },
      {
        path: "confirm",
        component: () => import("@/pages/withdrawal/confirm/withdrawal-confirm-page.vue"),
        name: routeNames.withdrawalConfirmPage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          backRoute: routeNames.withdrawalListPage,
          depth: 3,
          transaction: "withdrawal",
        },
      },
      {
        path: "complete",
        component: () => import("@/pages/withdrawal/complete/withdrawal-complete-page.vue"),
        name: routeNames.withdrawalCompletePage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          depth: 4,
          transaction: "withdrawal",
        },
      },
    ],
  },
  {
    path: "/empty",
    component: () => import("@/pages/common/empty/empty-page.vue"),
    name: routeNames.emptyPage,
  },
  {
    path: "/error",
    component: () => import("@/pages/common/error/error-page.vue"),
    name: routeNames.errorPage,
  },
  {
    path: "/form",
    component: () => import("@/pages/common/form/form-page.vue"),
    name: routeNames.formPage,
    meta: {
      hideClosingButton: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

export const routesV2: RouteRecordRaw[] = [
  {
    path: "",
    redirect: "/deposit",
  },
  {
    path: "/deposit",
    component: DepositPageV2,
    beforeEnter: [performanceMarksMiddleware],
    children: [
      {
        path: "",
        component: DepositListPageV2,
        name: routeNamesV2.depositListPage,
        meta: {
          depth: 0,
          transaction: "deposit",
        },
      },
      {
        path: "redirect",
        component: () => import("@/pages/v2/deposit/redirect/view/deposit-redirect-page.vue"),
        beforeEnter: [paymentMethodMiddleware],
        children: [
          {
            path: "",
            component: () => import("@/pages/v2/deposit/redirect/base/deposit-redirect-base-page.vue"),
            name: routeNamesV2.depositRedirectPage,
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            path: "instruction",
            component: () => import("@/pages/v2/deposit/redirect/instruction/deposit-redirect-instruction-page.vue"),
            name: routeNamesV2.depositRedirectInstructionPage,
            meta: {
              backRoute: routeNamesV2.depositRedirectPage,
              depth: 2,
              transaction: "deposit",
            },
          },
          {
            path: "eazype",
            component: () => import("@/pages/v2/deposit/redirect/eazype/deposit-redirect-eazype-page.vue"),
            name: routeNamesV2.depositRedirectEazypePage,
            beforeEnter: [paymentMethodMiddleware],
            meta: {
              backRoute: routeNamesV2.depositRedirectPage,
              depth: 3,
              transaction: "deposit",
            },
          },
          {
            path: "india-host-to-host",
            component: () =>
              import("@/pages/v2/deposit/redirect/india-host-to-host/deposit-redirect-india-host-to-host-page.vue"),
            name: routeNamesV2.depositRedirectIndiaHostToHostPage,
            beforeEnter: [paymentMethodMiddleware],
            meta: {
              backRoute: routeNamesV2.depositRedirectPage,
              depth: 3,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        path: "crypto",
        component: () => import("@/pages/v2/deposit/crypto/view/deposit-crypto-page.vue"),
        beforeEnter: [paymentMethodMiddleware],
        children: [
          {
            path: "",
            component: () => import("@/pages/v2/deposit/crypto/list/deposit-crypto-list-page.vue"),
            name: routeNamesV2.depositCryptoListPage,
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            path: "address",
            component: () => import("@/pages/v2/deposit/crypto/address/deposit-crypto-address-page.vue"),
            name: routeNamesV2.depositCryptoAddressPage,
            meta: {
              backRoute: routeNamesV2.depositCryptoListPage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        path: "group",
        component: () => import("@/pages/v2/deposit/group/view/deposit-group-page.vue"),
        beforeEnter: [paymentMethodMiddleware],
        children: [
          {
            path: "",
            component: () => import("@/pages/v2/deposit/group/list/deposit-group-list-page.vue"),
            name: routeNamesV2.depositGroupListPage,
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            path: "redirect",
            component: () => import("@/pages/v2/deposit/redirect/base/deposit-redirect-base-page.vue"),
            name: routeNamesV2.depositGroupBasePage,
            meta: {
              backRoute: routeNamesV2.depositGroupListPage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        path: "india",
        component: () => import("@/pages/v2/deposit/india/view/deposit-india-page.vue"),
        beforeEnter: [paymentMethodMiddleware],
        children: [
          {
            path: "",
            component: () => import("@/pages/v2/deposit/india/base/deposit-india-base-page.vue"),
            name: routeNamesV2.depositIndiaPage,
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            path: "invoice",
            component: () => import("@/pages/v2/deposit/india/invoice/deposit-india-invoice-page.vue"),
            name: routeNamesV2.depositIndiaInvoicePage,
            meta: {
              backRoute: routeNamesV2.depositIndiaPage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        path: "peer-to-peer",
        component: () => import("@/pages/v2/deposit/peer-to-peer/view/deposit-peer-to-peer-page.vue"),
        beforeEnter: [paymentMethodMiddleware],
        children: [
          {
            path: "",
            component: () => import("@/pages/v2/deposit/peer-to-peer/list/deposit-peer-to-peer-list-page.vue"),
            name: routeNamesV2.depositPeerToPeerListPage,
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            path: "base",
            component: () => import("@/pages/v2/deposit/peer-to-peer/base/deposit-peer-to-peer-base-page.vue"),
            name: routeNamesV2.depositPeerToPeerPage,
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 2,
              transaction: "deposit",
            },
          },
          {
            path: "invoice",
            component: () => import("@/pages/v2/deposit/peer-to-peer/invoice/deposit-peer-to-peer-invoice-page.vue"),
            name: routeNamesV2.depositPeerToPeerInvoicePage,
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 3,
              transaction: "deposit",
            },
          },
          {
            path: "instruction",
            component: () =>
              import("@/pages/v2/deposit/peer-to-peer/instruction/deposit-peer-to-peer-instruction-page.vue"),
            name: routeNamesV2.depositPeerToPeerInstructionPage,
            meta: {
              backRoute: routeNamesV2.depositPeerToPeerInvoicePage,
              depth: 4,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        path: "host-to-host",
        component: () => import("@/pages/v2/deposit/host-to-host/deposit-host-to-host-page.vue"),
        name: routeNamesV2.depositHostToHostPage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          backRoute: routeNamesV2.depositListPage,
          depth: 2,
          transaction: "deposit",
        },
      },
      {
        path: "bonus-details",
        component: () => import("@/pages/v2/deposit/bonus/deposit-bonus-details-page.vue"),
        name: routeNamesV2.depositBonusDetailsPage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          backRoute: routeNamesV2.previousPage,
          depth: 3,
          transaction: "deposit",
        },
      },
      {
        path: "complete",
        component: () => import("@/pages/v2/deposit/complete/deposit-complete-page.vue"),
        name: routeNamesV2.depositCompletePage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          depth: 4,
          transaction: "deposit",
        },
      },
    ],
  },
  {
    path: "/withdrawal",
    component: () => import("@/pages/v2/withdrawal/view/withdrawal-page.vue"),
    beforeEnter: [performanceMarksMiddleware],
    children: [
      {
        path: "",
        component: () => import("@/pages/v2/withdrawal/list/withdrawal-list-page.vue"),
        name: routeNamesV2.withdrawalListPage,
        meta: {
          depth: 0,
          transaction: "withdrawal",
        },
      },
      {
        path: "redirect",
        component: () => import("@/pages/v2/withdrawal/redirect/view/withdrawal-redirect-page.vue"),
        beforeEnter: [paymentMethodMiddleware],
        children: [
          {
            path: "",
            component: () => import("@/pages/v2/withdrawal/redirect/base/withdrawal-redirect-base-page.vue"),
            name: routeNamesV2.withdrawalRedirectPage,
            meta: {
              backRoute: routeNamesV2.withdrawalListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            path: "instruction",
            component: () =>
              import("@/pages/v2/withdrawal/redirect/instruction/withdrawal-redirect-instruction-page.vue"),
            name: routeNamesV2.withdrawalRedirectInstructionPage,
            meta: {
              backRoute: routeNamesV2.withdrawalRedirectPage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        path: "nigerian-banks",
        component: () => import("@/pages/v2/withdrawal/nigerian-banks/withdrawal-nigerian-banks-page.vue"),
        name: routeNamesV2.withdrawalNigerianBanksPage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          backRoute: routeNamesV2.withdrawalListPage,
          depth: 1,
          transaction: "withdrawal",
        },
      },
      {
        path: "group",
        component: () => import("@/pages/v2/withdrawal/group/view/withdrawal-group-page.vue"),
        beforeEnter: [paymentMethodMiddleware],
        children: [
          {
            path: "",
            component: () => import("@/pages/v2/withdrawal/group/list/withdrawal-group-list-page.vue"),
            name: routeNamesV2.withdrawalGroupListPage,
            meta: {
              backRoute: routeNamesV2.withdrawalListPage,
              depth: 1,
              transaction: "withdrawal",
            },
          },
          {
            path: "redirect",
            component: () => import("@/pages/v2/withdrawal/redirect/base/withdrawal-redirect-base-page.vue"),
            name: routeNamesV2.withdrawalGroupBasePage,
            meta: {
              backRoute: routeNamesV2.withdrawalGroupListPage,
              depth: 2,
              transaction: "withdrawal",
            },
          },
        ],
      },
      {
        path: "confirm",
        component: () => import("@/pages/v2/withdrawal/confirm/withdrawal-confirm-page.vue"),
        name: routeNamesV2.withdrawalConfirmPage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          backRoute: routeNamesV2.withdrawalListPage,
          depth: 3,
          transaction: "withdrawal",
        },
      },
      {
        path: "complete",
        component: () => import("@/pages/v2/withdrawal/complete/withdrawal-complete-page.vue"),
        name: routeNamesV2.withdrawalCompletePage,
        beforeEnter: [paymentMethodMiddleware],
        meta: {
          depth: 4,
          transaction: "withdrawal",
        },
      },
    ],
  },
  {
    path: "/empty",
    component: () => import("@/pages/v2/common/empty/empty-page.vue"),
    name: routeNamesV2.emptyPage,
  },
  {
    path: "/error",
    component: () => import("@/pages/v2/common/error/error-page.vue"),
    name: routeNamesV2.errorPage,
  },
  {
    path: "/form",
    component: () => import("@/pages/v2/common/form/form-page.vue"),
    name: routeNamesV2.formPage,
    meta: {
      hideClosingButton: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];
