<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import { useParentStore } from "@/entities/parent";
import { useUserStore } from "@/entities/user";
import { useAnalytics } from "@/features/analytics";
import { useModalStore } from "@/features/modal";
import { routeNamesV2 } from "@/shared/constants";
import { bridgeService } from "@/shared/services";
import { Button, Container, IconBox, Paragraph, Title } from "@/shared/ui-v2";

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const { parentData } = storeToRefs(useParentStore());

const { isNativePlatform } = storeToRefs(useUserStore());

const { changeIsOpen } = useModalStore();

const { sendExitEvent } = useAnalytics();

const title = computed(() => {
  const { backRoute, transaction } = route.meta;

  if (backRoute || !transaction) {
    return "";
  }

  return t(`${transaction}.v2.title`);
});

const goBack = () => {
  // commented during the task BILLING-4948
  // sendButtonEvent({ button_text: "button_back" });

  if (route.meta.backRoute === routeNamesV2.previousPage) {
    router.back();
    return;
  }

  router.push({ name: route.meta.backRoute });
};

const close = () => {
  sendExitEvent();

  if (isNativePlatform.value) {
    changeIsOpen(true);
    return;
  }

  bridgeService.notify({ messageType: "onClose" });
};
</script>

<template>
  <header :class="$style.root">
    <Container
      :class="$style.container"
      type="complete"
    >
      <div :class="$style.wrapper">
        <div :class="$style.contentLeft">
          <Title
            v-if="title"
            size="l"
          >
            {{ title }}
          </Title>
          <Button
            v-else-if="route.meta.backRoute"
            variant="plain"
            @click="goBack"
          >
            <IconBox name="chevron-left" />
            <Paragraph size="m-sb">
              {{ t("button.back") }}
            </Paragraph>
          </Button>
        </div>
        <Button
          v-if="parentData.closingButton && !route.meta.hideClosingButton"
          variant="plain"
          @click="close"
        >
          <IconBox
            color="tertiary"
            name="cross"
          />
        </Button>
      </div>
    </Container>
  </header>
</template>

<style module lang="postcss">
.root {
  height: var(--size-header);
}

.container {
  display: flex;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-8);
  width: 100%;
}

.contentLeft {
  display: inline-flex;
}
</style>
