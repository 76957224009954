type PropertyName = string | number | symbol;
type Many<T> = T | ReadonlyArray<T>;
type PropertyPath = Many<PropertyName>;

interface MappingMethods {
  omit<T extends object, K extends PropertyName[]>(
    object: T | null | undefined,
    ...paths: K
  ): Pick<T, Exclude<keyof T, K[number]>>;

  omit<T extends object, K extends keyof T>(object: T | null | undefined, ...paths: Array<Many<K>>): Omit<T, K>;

  omit<T extends object>(object: T | null | undefined, ...paths: Array<Many<PropertyName>>): Partial<T>;

  pick<T extends object, U extends keyof T>(object: T, ...props: Array<Many<U>>): Pick<T, U>;

  pick<T>(object: T | null | undefined, ...props: Array<Many<PropertyPath>>): Partial<T>;

  toString(value: unknown): string;
}

const pick = <T extends object, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> => {
  const result = {} as Pick<T, K>;

  for (const key of keys) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = obj[key];
    }
  }

  return result;
};

const omit = <T extends Record<string, unknown>, K extends keyof T>(
  obj: T | null | undefined,
  keys: Array<K>,
): Omit<T, K> => {
  const omittedKeys = new Set<string | number | symbol>(keys);
  const result: Partial<T> = {};

  for (const key in obj) {
    if (!omittedKeys.has(key)) {
      result[key as keyof T] = obj[key];
    }
  }

  return result as Omit<T, K>;
};

const toString = (value: unknown): string => {
  // null || undefined
  if (value == null) {
    return "";
  }

  if (typeof value === "number" && value === 0 && 1 / value === -Infinity) {
    return "-0";
  }

  return String(value);
};

export default {
  toString,
  omit,
  pick,
} as MappingMethods;
