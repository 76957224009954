import { storeToRefs } from "pinia";

import { usePaymentMethodStore } from "@/entities/payment-method";
import { useUserStore, type Snippet } from "@/entities/user";
import { useFormStore, getAmount, getRandomAmount } from "@/features/form";

export const useChangeFormAmount = () => {
  const formStore = useFormStore();
  const { amount } = storeToRefs(formStore);
  const { changeAmount } = formStore;

  const userStore = useUserStore();
  const { snippetLoadPromise } = userStore;
  const { snippet } = storeToRefs(userStore);

  const { limits } = storeToRefs(usePaymentMethodStore());

  const setFormAmount = async () => {
    await snippetLoadPromise;
    if (!amount.value) {
      changeAmount(getAmount(snippet.value as Snippet, limits.value));
    }
  };

  const setRandomFormAmount = async () => {
    await snippetLoadPromise;
    if (!amount.value || amount.value === getAmount(snippet.value as Snippet, limits.value)) {
      changeAmount(getRandomAmount(snippet.value as Snippet, limits.value));
    }
  };

  return { setFormAmount, setRandomFormAmount };
};
