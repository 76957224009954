<script setup lang="ts">
import { defineAsyncComponent } from "vue";

import { fetchIcon } from "@/shared/lib";
import type { CssClass } from "@/shared/ui-v2";

interface Props {
  color?: "inherit" | "accent" | "informative" | "secondary" | "tertiary" | "warning";
  name: Nullable<string>;
  size?: "auto" | "full" | "xs" | "s" | "m" | "l" | "xl";
  space?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  color: "inherit",
  size: "auto",
  space: true,
});

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  inherit: "",
  accent: "color-accent",
  informative: "color-informative",
  secondary: "color-secondary",
  tertiary: "color-tertiary",
  warning: "color-warning",
};

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  auto: "",
  full: "size-full",
  xs: "size-xs",
  s: "size-s",
  m: "size-m",
  l: "size-l",
  xl: "size-xl",
};

const icon = defineAsyncComponent(() => fetchIcon(props.name ?? "none"));
</script>

<template>
  <div :class="[$style.root, $style[colors[color]], $style[sizes[size]], space && $style.space]">
    <Suspense>
      <component
        :is="icon"
        v-if="icon"
      />
      <template #fallback>
        <div :class="$style.placeholder" />
      </template>
    </Suspense>
  </div>
</template>

<style module lang="postcss">
.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: color 0.25s;
}

.color-accent {
  color: var(--color-brand-accent);
}

.color-informative {
  color: var(--color-informative);
}

.color-secondary {
  color: var(--color-fg-secondary);
}

.color-tertiary {
  color: var(--color-fg-tertiary);

  &:hover {
    color: var(--color-fg-tertiary-hover);
  }
}

.color-warning {
  color: var(--color-warning);
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes shimmer {
  0% {
    background-position-x: 100%;
  }
  56% {
    background-position-x: -100%;
  }
  100% {
    background-position-x: -100%;
  }
}

.placeholder {
  height: 100%;
  min-width: 10px;
  width: 100%;
  aspect-ratio: 1 / 1;

  backdrop-filter: blur(5px);
  border-radius: 50%;

  background: var(--color-content-skeleton);
  background-size: 200% 100%;
  background-position-x: -100%;

  opacity: 0;
  animation:
    fadeIn 0.2s forwards 0.1s,
    shimmer 1.8s infinite linear 0.1s;
}

.size-full {
  & > svg,
  & > .placeholder {
    height: 100%;
  }
}

.size-xs {
  & > svg,
  & > .placeholder {
    width: 12px;
    height: 12px;
  }
}

.size-s {
  & > svg,
  & > .placeholder {
    width: 16px;
    height: 16px;
  }
}

.size-m {
  & > svg,
  & > .placeholder {
    width: 20px;
    height: 20px;
  }

  &.space {
    padding: var(--spacing-2);
  }
}

.size-l {
  & > svg,
  & > .placeholder {
    width: 24px;
    height: 24px;
  }

  &.space {
    padding: var(--spacing-4);
  }
}

.size-xl {
  & > svg,
  & > .placeholder {
    width: 48px;
    height: 48px;
  }

  &.space {
    padding: var(--spacing-8);
  }
}
</style>
