<script setup lang="ts">
import type { PaymentMethodBadgeVariant } from "@/entities/payment-method";
import BrandIcon from "@/entities/payment-method/assets/icons/brand.svg?raw";

interface Props {
  variant: PaymentMethodBadgeVariant;
}

defineProps<Props>();

const content: Record<NotUndefined<Props["variant"]>, string> = {
  brand: BrandIcon,
  fast: "Fast",
  instant: "Instant",
  top: "Top",
  turbo: "Turbo",
};
</script>

<template>
  <div :class="[$style.root, $style[variant]]">
    <span
      v-sanitize-html="content[variant]"
      :class="$style.content"
    />
  </div>
</template>

<style module lang="postcss">
.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  padding: 0 6px;
  border-radius: 29px;

  &.brand {
    background-color: var(--color-bg-badge-method-brand);
    color: var(--color-fg-badge-method-brand);
  }

  &.fast {
    background-color: var(--color-bg-badge-method-fast);
    color: var(--color-fg-badge-method-fast);
  }

  &.instant {
    background-color: var(--color-bg-badge-method-instant);
    color: var(--color-fg-badge-method-instant);
  }

  &.top {
    background-color: var(--color-bg-badge-method-top);
    color: var(--color-fg-badge-method-top);
  }

  &.turbo {
    background-color: var(--color-bg-badge-method-turbo);
    color: var(--color-fg-badge-method-turbo);
  }
}

.content {
  font-family: var(--text-body-font-family);
  font-weight: 800;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}
</style>
