import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

import type { Snippet, User } from "@/entities/user";
import { defaultUser } from "@/entities/user";

const NAMESPACE = "user";

export const useUserStore = defineStore(NAMESPACE, () => {
  const snippet = ref<Nullable<Snippet>>(null);
  const user = ref<User>(defaultUser);

  const snippetLoadPromise = new Promise<void>((resolve) => {
    const unwatch = watch(snippet, (newValue) => {
      if (newValue) {
        resolve();
        unwatch();
      }
    });
  });

  const balance = computed(() => {
    const isPrimary = user.value.balanceData.primary.currency === user.value.currency;

    if (isPrimary) {
      return {
        ...user.value.balanceData.primary,
        isPrimary,
      };
    }

    return {
      amount: user.value.balanceData[user.value.currency] ?? 0,
      currency: user.value.currency,
      isPrimary,
    };
  });

  const country = computed(() => user.value.paymentGeo.toLowerCase() as User["country"]);

  const isNativePlatform = computed(() => ["android", "ios"].includes(user.value.platform));

  const changeSnippet = (data: Snippet) => {
    snippet.value = data;
  };

  const changeUser = (data: User) => {
    user.value = data;
  };

  return {
    user,
    balance,
    country,
    isNativePlatform,
    snippet,
    snippetLoadPromise,
    changeSnippet,
    changeUser,
  };
});
