<script setup lang="ts">
import { defineAsyncComponent } from "vue";

import { fetchIcon } from "@/shared/lib";
import type { TailwindClass } from "@/shared/ui";

interface Props {
  name: Nullable<string>;
  placeholderHeight?: string;
  radius?: "none" | "full";
}

const props = withDefaults(defineProps<Props>(), {
  radius: "none",
  placeholderHeight: "100%",
});

const radiuses: Record<NotUndefined<Props["radius"]>, TailwindClass> = {
  none: "",
  full: "rounded-full",
};

const icon = defineAsyncComponent(() => fetchIcon(props.name ?? "none"));
</script>

<template>
  <div
    class="[&>svg]:rounded-inherit [&>svg]:w-inherit inline-flex items-center justify-center"
    :class="[radiuses[radius]]"
  >
    <Suspense>
      <component :is="icon" />
      <template #fallback>
        <div
          :class="[$style.placeholder]"
          :style="{ height: placeholderHeight }"
        />
      </template>
    </Suspense>
  </div>
</template>

<style module lang="postcss">
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes shimmer {
  0% {
    background-position-x: 100%;
  }
  56% {
    background-position-x: -100%;
  }
  100% {
    background-position-x: -100%;
  }
}

.placeholder {
  min-width: 10px;
  width: 100%;
  aspect-ratio: 1 / 1;

  backdrop-filter: blur(5px);
  border-radius: 50%;

  background: linear-gradient(
    90deg,
    rgba(234, 234, 234, 0.1) 8%,
    rgba(155, 158, 166, 0.24) 16%,
    rgba(234, 234, 234, 0.1) 32%
  );
  background-size: 200% 100%;
  background-position-x: -100%;

  opacity: 0;
  animation:
    fadeIn 0.2s forwards 0.1s,
    shimmer 1.8s infinite linear 0.1s;
}
</style>
