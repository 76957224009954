import type { Limits } from "@/entities/payment-method";
import type { Snippet } from "@/entities/user";
import { suggestedAmounts } from "@/features/form";
import { omit } from "@/shared/lib";

const splitArrayToSubArrays = (array: number[], count: number) => {
  if (count < 2) {
    return [array];
  }

  const result = [];
  let i = 0;

  for (let j = 0; j < count; j++) {
    const size = Math.ceil((array.length - i) / (count - j));
    result.push(array.slice(i, (i += size)));
  }

  return result;
};

const getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

export const getAmount = (snippet: Snippet, limits: Limits) => {
  const { paymentAmountSnippet = 0 } = snippet;
  const { min, max } = limits;

  if (paymentAmountSnippet < min) {
    return String(min);
  }

  if (paymentAmountSnippet > max) {
    return String(max);
  }

  return String(paymentAmountSnippet);
};

export const getRandomAmount = (snippet: Snippet, limits: Limits): string => {
  const next = +getAmount(snippet, limits) + getRandomInt(-9, 9);

  if (next <= limits.min || next >= limits.max) {
    return getRandomAmount(snippet, limits);
  }

  return String(next);
};

export const generateAmountTags = (min: Limits["min"], max: Limits["max"], count: number) => {
  const amounts = suggestedAmounts.filter((item) => item <= max && item >= min);

  return splitArrayToSubArrays(amounts, count).map((group, index) =>
    index === 0 ? group[0] : group[group.length - 1],
  );
};

export const generateAndSubmitForm = (query: Dictionary<string>) => {
  const form = document.createElement("form");

  form.setAttribute("action", query.action);
  form.setAttribute("method", query.method);

  for (const [key, value] of Object.entries(omit(query, ["action", "method"]))) {
    const input = document.createElement("input");

    input.setAttribute("name", key);
    input.setAttribute("value", value);
    input.setAttribute("type", "hidden");

    form.append(input);
  }

  document.body.append(form);

  form.submit();
};

export const extractAmount = (value: string) => value.replace(/[^[0-9\s,.]+/g, "").trim();
