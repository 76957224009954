import type { Ref } from "vue";
import { computed } from "vue";

import type { PaymentMethodBadge } from "@/entities/payment-method";

interface Props {
  isActive: Ref<boolean>;
}

export const useBadge = (props: Props) => {
  const badge = computed<PaymentMethodBadge>(() => ({
    color: "secondary",
    customClass: {
      content: "-right-1 -top-1 w-6 h-6 rounded-5.25xl shadow-md",
      icon: "text-white",
      root: "",
    },
    icon: "check",
    isVisible: props.isActive.value,
  }));

  return {
    badge,
  };
};
